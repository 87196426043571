.features {
  & > div:nth-child(5) {
    & > div:nth-child(1) {
      & > a {
        & > div:first-child {
          transition: transform .5s;
          transform: scale(1);
        }
      }
      & > a:hover {
        & > div:first-child {
          transform: scale(1.2);
        }
      }
    }
  }
}

.teachers {
  & > div:nth-child(2) {
    & > div:nth-child(1) {
      & > div {
        & > div:first-child {
          transition-duration: .5s;
        }
        &:hover {
          & > div:first-child {
            transform: scale(1.2);
          }
        }
      }
    }
  }
}

.aplications {
  & > div:first-child {
    & > div:first-child {
      & > div:first-child {
        & > div:nth-child(2) {
          & > div:nth-child(2) {
            & > a {
              &:active {
                & > div {
                  --tw-bg-opacity: .3;
                }
              }
            }
          }
        }
      }
    }
  }
}