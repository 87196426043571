@import '../abstracts/variables';

.navbar {
    height: 5rem;
    display: block;
    width: 100%;

    > .container {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .item {
        width: auto;
        height: 100%;
        padding: 1rem 0.75rem;
        display: flex;
        flex-shrink: 1;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        white-space: nowrap;

        &.brand {
            padding: 1rem 0;
        }

        &.spacer {
            padding: 0;
            flex: 1 1 auto;
        }

        &.divider {
            padding: 0;
            margin: 0 0.75rem;
            border-left: $border-base-width solid $gray-04;
        }
    }

    &.clean {
        height: 5rem;

        > .container {
            border: 0 none;
        }
    }

    &.compact {
        height: 3.5rem;

        .item {
            padding: 0.375rem;

            &.brand {
                padding: 0.375rem 0;
            }
        }

        &.clean {
            height: 3.5rem;
        }

    }

}
