@import '../abstracts/variables';

.footer {
    & > div {
        & > div:first-child {
            & > div:nth-child(2) {
                & > div:first-child {
                    & > div:first-child {
                        & > div:nth-child(3) {
                            & > ul {
                                & > li {
                                    & > a {
                                        &:active {
                                            & > div {
                                                --tw-bg-opacity: .3;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// .footer {
//     & > div:first-child {
//         & > div:first-child {
//             & > div:last-child {
//                 & > div:first-child {
//                     height: 1px;
//                 }
//             }
//             & > div:nth-child(3) {
//                 & > div:first-child {
//                     & > div:first-child {
//                         height: 1px;
//                     }
//                 }
//             }
//         }
//     }
//     & > div:last-child {
//         & > div:nth-child(2) {
//             & > div:first-child {
//                 & > div:first-child {
//                     & > div:first-child {
//                         & > div:first-child {
//                             & > div:first-child {
//                                 & > ul {
//                                     & > li:last-child {
//                                         margin-bottom: 0;
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }

//             }
//             & > div:nth-child(2) {
//                 & > div:first-child {
//                     & > div:first-child {
//                         height: 1px;
//                     }
//                 }
//             }
//         }
//         }
// }

// @media screen and (min-width: 768px) {
//     .footer {
//         & > div:first-child {
//             & > div:first-child {
//                 z-index: 2;
//                 & > div:first-child {
//                     & > p {
//                     }
                    
//                 }
//                 & > div:last-child {
//                     & > div:first-child {
//                         margin-bottom: 1.5625rem;
//                     }
//                 }
//             }
//             & > div:nth-child(2) {
//                 margin-bottom: .1875rem;
//                 & > div:first-child {
//                     & > div:first-child {
//                         & > div:first-child {
//                             & > div:last-child {
//                                 margin-top: 3.1875rem;
//                             }
//                         }
//                     }
//                 }
//             }
//             & > div:last-child {
//                 width: 50vw;
//                 height: 30rem;
//                 z-index: 1;
//                 left: auto;
//                 right: 48rem;
//                 border-top-right-radius: 10rem;
//                 & > div:last-child {
//                     & > div:first-child {
//                         margin-bottom: 1.68rem;
//                     }
//                 }
//             }
//         }
//         & > div:last-child {
//             & > div:last-child {
//                 & > div:nth-child(0) {
//                     width: max-content;
//                 }
//             }
//             & > div:nth-child(2) {
//                 & > div:nth-child(1) {
//                 }
//                 & > div:last-child {
//                     margin-top: 3.1875rem;
//                 }
//             }
//         }
//     }
// }